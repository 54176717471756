import { Link } from "react-router-dom";

export default function Header() {
    return (
        <header className="fixed top-0 z-50 w-full h-16 bg-bgcolor-light shadow-md dark:bg-dbgcolor-dark">
            <div className="flex flex-row justify-between gap-6 h-full max-w-screen-xl mx-auto px-4 font-display select-none sm:px-6 sm:gap-8">
                <div className="flex flex-row items-center gap-6 text-2xl sm:gap-8 sm:text-3xl">
                    <Link
                        className="font-semibold text-accent-light dark:text-accent-dark"
                        to="/"
                    >
                        zaneunity
                    </Link>
                </div>
                <div className="hidden flex-row items-center gap-6 text-xl sm:flex sm:gap-8">
                    <a
                        className="transition-colors hover:text-accent-light dark:hovertext-accent-dark"
                        href="https://zanecraft.net"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Zanecraft
                    </a>
                    <a
                        className="transition-colors hover:text-accent-light dark:hovertext-accent-dark"
                        href="https://discord.gg/zane"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Discord
                    </a>
                    <a
                        className="transition-colors hover:text-accent-light dark:hovertext-accent-dark"
                        href="https://youtube.com/zanety"
                        target="_blank"
                        rel="noreferrer"
                    >
                        YouTube
                    </a>
                </div>
            </div>
        </header>
    );
}
