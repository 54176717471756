import Card from "./Card";
import Footer from "./Footer";
import Header from "./Header";
import Main from "./Main";

export default function App() {
    return (
        <>
            <Header />
            <Main>
                <Card
                    link="https://zanecraft.net"
                    title="Sei dabei bei"
                    subtitle="Zanecraft&nbsp;2"
                    text="Nachdem die erste Staffel von Zanecraft ein so großer Erfolg war, haben wir beschlossen die Serie fortzusetzen und Zanecraft&nbsp;2 ist am 08.12.24 gestartet. Sei dabei bei der zweiten Staffel von Zanecraft!"
                    image="/images/zanecraft.jpg"
                    reverse={false}
                />
                <Card
                    link="https://discord.gg/zane"
                    title="Komm auf"
                    subtitle="Discord"
                    text="Trete unserem Discord-Server bei und lerne coole neue Freunde kennen. Hier veranstalten wir regelmäßig spannende Events. Mittlerweile haben wir über 1200 Mitglieder und unsere Community wächst täglich weiter!"
                    image="/images/discord.jpg"
                    reverse={true}
                />
                <Card
                    link="https://youtube.com/zanety"
                    title="Folge mir auf"
                    subtitle="YouTube"
                    text="Auf meinem YouTube-Kanal findest du viele spannende Videos rund um Minecraft. Aktuell spiele ich hauptsächlich Minecraft Survival und Hardcore, aber in Zukunft werden noch viele weitere Projekte folgen!"
                    image="/images/banner.jpg"
                    reverse={false}
                />
                <Card
                    link="https://open.spotify.com/playlist/0jcacc6AKk4TiDYlbXrxzN"
                    title="Hör unsere"
                    subtitle="Playlist"
                    text="Unsere öffentliche Spotify-Playlist Open Summit enthält die besten Pop Songs der letzten Jahre. Open Summit hat mittlerweile mehr als 1000 Songs, ist über 60 Stunden lang und wird regelmäßig um aktuelle Songs erweitert!"
                    image="/images/spotify.jpg"
                    reverse={true}
                />
            </Main>
            <Footer />
        </>
    );
}
