import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer className="flex-initial w-full bg-dbgcolor-light dark:bg-dbgcolor-dark">
            <div className="flex flex-col text-center items-center gap-3 max-w-screen-xl mx-auto p-6 sm:flex-row sm:justify-between">
                <div className="flex flex-row justify-center w-60 gap-4 sm:justify-start sm:gap-8">
                    <Link
                        className="text-md transition-colors hover:text-accent-light dark:hovertext-accent-dark sm:text-lg"
                        to="/"
                    >
                        Home
                    </Link>
                </div>
                <div className="text-md sm:w-full sm:text-lg">
                    Copyright © {new Date().getFullYear()} by zane
                </div>
                <div className="flex flex-row justify-center w-60 gap-4 sm:justify-end sm:gap-8">
                    <Link
                        className="text-md transition-colors hover:text-accent-light dark:hovertext-accent-dark sm:text-lg"
                        to="/imprint"
                    >
                        Impressum
                    </Link>
                </div>
            </div>
        </footer>
    );
}
